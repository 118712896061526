body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

/* TEXT */
h1 {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 3px solid black;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.dashTitle {
  font-size: 18px;
}
.dashTitle span {
  display: inline-block;
}
.dashTitle span:before,
.dashTitle span:after {
  content: "";
  border-bottom: 3px solid white;
  display: inline-block;
  width: 50px;
  margin-bottom: 5px;
}
.dashTitle span:before {margin-right: 10px;}
.dashTitle span:after {margin-left: 10px;}

/* HEADER */
#mainHeader .active{
  background-color: rgb(231, 84, 89);
  border-radius: 10px;
}
#mainHeader .nav-link{
  margin-right: 10px;
}

/* MENU */
.menu {
  text-align: center;
  background-color: #0D66A8;
  color: white;
  padding: 20px;
  margin: 20px;
  /* responsive à faire */
  /* position: absolute;
  right: 30px;
  top: 100px; */
}

.linkMenu {
  background-color: white !important;
  color: #0D66A8;
  padding: 5px 40px !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
.linkMenu .fas {
  margin-right: 15px;
}

/* BUTTONS  */
.btn-lqo {
  border-radius: 0;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn-lqo-primary {
  color: #fff !important;
  background-color: #BB3234 !important;
  border-color: #BB3234;
}
.btn-lqo-primary:hover {background-color: rgb(233, 68, 71) !important;}

.btn-lqo-secondary {
  color: #fff !important;
  background-color: #0D66A8 !important;
  border-color: #0D66A8;
}
.btn-lqo-secondary:hover {background-color: rgb(24, 126, 204) !important;}

.btn-lqo-green {
  color: #fff;
  background-color: #0DA80D !important;
  border-color: #0DA80D;
}
.btn-lqo-green:hover {background-color: rgb(20, 201, 20)!important;}

/* FORM */
label {
  font-weight: bold;
  text-align: right;
}
.form-control::-webkit-input-placeholder { color: #D4D4D4; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #D4D4D4; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #D4D4D4; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #D4D4D4; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #D4D4D4; }  /* Microsoft Edge */

/* ANIMATION */
.animated {
  animation-duration: 1s; 
}

.animated.infinite {
  animation-iteration-count: infinite; 
}

.animated.hinge {
  animation-duration: 2s; 
}

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; 
}

.preview {
  font-size: 14px;
  border: 1px solid rgb(187, 50, 52);
  padding: 20px;
}
.preview h4 {
  text-align: center;
  color: #bb3234;
}
.preview .form-group {
  margin-bottom: 8px;
}
.preview .col-form-label {
  padding:0;
}
